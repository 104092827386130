<template>
  <section class="home d-flex align-items-center">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <div class="home-text">
            <h1>Hemodialisa</h1>
            <p>
              Hemodialisa adalah tindakan yang dilakukan untuk membantu beberapa
              fungsi ginjal yang terganggu atau rusak saat ginjal tidak lagi
              mampu melaksanakannya. Hemodialisa membantu menjaga keseimbangan
              cairan dan elektrolit pada tubuh, juga membantu mengekskresikan
              zat-zat sisa atau buangan.
            </p>
            <br />
            <p>
              Saat ini dengan teknologi medis yang semakin berkembang, pemenuhan
              kebutuhan dan pemahaman yang lebih baik tentang gagal ginjal dan
              proses dialisa, pasien dapat menjalani gaya hidup yang sehat.
              Pasien dalam keseharian dapat menjalani aktivitas secara normal
              dengan pengobatan hemodialisa secara rutin dan teratur. Perlakuan
              hemodialisa pada pasien dengan gagal ginjal secara ideal sebaiknya
              dilakukan 2 atau 3 sesi seminggu dengan tiap sesi berlangsung
              selama kurang lebih 4 jam.
            </p>
            <br />
            <p>
              Saat ini pasien hemodialisa dapat melakukan perjalanan jauh,
              mereka dapat menyesuaikan sesi hemodialisa mereka untuk dilakukan
              di tempat lain sesuai dengan jadwal dan tempat tujuan mereka.
              Dengan peran serta dokter, maka gaya hidup pasien hemodialisa saat
              ini memungkinkan mereka untuk melakukan perjalanan jauh termasuk
              ke luar negeri.
            </p>
          </div>
        </div>
        <div class="col-md-6 text-center">
          <img :src="require('../../assets/layanan/hemodialisa.jpg')" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  created() {
    document.title = "Hemodialisa RSUD GAMBIRAN KOTA KEDIRI";
  },
};
</script>
